






import { Component } from 'vue-property-decorator'

import { AbstractAddon } from '../../../abstract/addon-abstract'
import CustomerSupportModuleUi
  from '../../../../../modules/CustomerSuport/ui/CustomerSupport.ui.vue'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<CustomerSupportAddon>({
  name: 'CustomerSupportAddon',
  components: { CustomerSupportModuleUi }
})
export class CustomerSupportAddon extends AbstractAddon {}

export default CustomerSupportAddon
